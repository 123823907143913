import { MotifMedicalLogo } from "resources/images"

export default function Header() : JSX.Element {
  return (
    <div className="flex justify-between my-4 mx-auto">
      <img
        src={MotifMedicalLogo}
        width={120}
        height={120}
      />
    </div>
  )
}