import { LineItemType } from 'types'
import { moneyFormatter } from 'utils'

function LineItem({ item }: { item: LineItemType }) {

  return (
    <div className="flex justify-between">
      <div className="flex flex-col">
        <p className="font-bold">{item.item_sku}</p>
        <p className="text-slategray">{item.item_description}</p>
        <p className="text-slategray text-sm">{`(${moneyFormatter.format( item.item_unitPrice )} ea.) x ${item.item_quantity}`}</p>
      </div>
      <p className="text-slategray">{`${moneyFormatter.format( item.item_unitPrice * item.item_quantity )}`}</p>
    </div>
  )
}

export default LineItem