import { moneyFormatter, formatDate } from "utils"
import { InvoiceData } from "types"
import { useInvoiceDataStore } from "store"
import ThankYouHeader from "components/thankYou/Header"
import { CheckboxInput } from "components/forms/components"
import { useNavigate } from "react-router-dom"

type InvoiceSelectionListProps = {
  location?: string;
  invoices: InvoiceData[];
  selectMultipleInvoices: boolean;
}

export default function InvoiceSelectionList({location, invoices, selectMultipleInvoices}: InvoiceSelectionListProps ) : JSX.Element {
  const { setCurrentInvoice, paidInvoices, selectedInvoices, addSelectedInvoice } = useInvoiceDataStore()
  const navigate = useNavigate()
  // Filter function that returns true if a match is not found in the paidInvoices array
  const filterPaidInvoices = ( _invoice : InvoiceData ) => !paidInvoices.find( ( _pInvoice : InvoiceData ) => _pInvoice.invoice_invoiceNumber === _invoice.invoice_invoiceNumber )

  const orderedInvoices = [ ...new Map( invoices.map( ( i ) => [ i.invoice_invoiceNumber, i ] ) ).values() ]

  function handleInvoiceSelection( i: InvoiceData ) {
    addSelectedInvoice( i.invoice_invoiceNumber )
    if ( !selectMultipleInvoices ) {
      setCurrentInvoice( i.invoice_invoiceNumber )
      navigate( `/invoice-details/`.concat( window.location.search ) )
    }
  }

  if ( paidInvoices.length === orderedInvoices.length ) return ( <><p className="mt-4 ml-4 text-teal">{`All Invoices Paid`}</p><ThankYouHeader /></> )

  return (
    <div className="max-w-xl">
      { location && <h2>{`${location}`}</h2> }
      {orderedInvoices.filter( filterPaidInvoices ).map( ( invoice : InvoiceData ) => {
        return (
          <div key={invoice.invoice_invoiceNumber} className="flex items-center cursor-pointer">
            {
              selectMultipleInvoices &&
                  <CheckboxInput
                    name={invoice.invoice_invoiceNumber}
                    value={invoice.invoice_invoiceNumber}
                    onChange={() => handleInvoiceSelection( invoice )}
                    label={undefined}
                    id={undefined}
                    reference={undefined}
                    checked={selectedInvoices.includes( invoice.invoice_invoiceNumber )}
                  />
            }
            <div
              key={invoice.invoice_invoiceNumber} className="pointer w-full" data-cy={`invoice-${invoice.invoice_invoiceNumber}`}
              onClick={() => handleInvoiceSelection( invoice )}
            >
              <ul className={`p-8 rounded-lg border-2 mt-4 ${selectedInvoices.includes( invoice.invoice_invoiceNumber ) ? `border-slategray` : `border-lightslategray`}`}>
                <li className="cursor-pointer underline">{`Invoice Number: ${invoice.invoice_invoiceNumber}`}</li>
                {invoice.invoice_poNumber ? <li>{`PO Number: ${invoice.invoice_poNumber}`}</li> : ``}
                {invoice.invoice_invoiceDate ? <li>{`Invoice Date: ${formatDate( invoice.invoice_invoiceDate )}`}</li> : ``}
                {invoice.invoice_dueDate ? <li>{`Invoice Due Date: ${formatDate( invoice.invoice_dueDate )}`}</li> : ``}
                <li>{`Invoice total: ${moneyFormatter.format( invoice.invoice_total )}`}</li>
              </ul>
            </div>
          </div>
        )
      }
      )}
    </div>
  )
}