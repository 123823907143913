/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { BankPaymentMethod, CardPaymentMethod, IPaymentMethod, identifyPaymentMethodType } from 'types/paymentMethods.d'
import create from 'zustand'

export interface PaymentStore {
  customerProfileId: string | null;
  setCustomerProfileId: ( _id : string ) => void;
  paymentMethods: IPaymentMethod[];
  setPaymentMethods: ( _pm : IPaymentMethod[] ) => void;
  isEmpty: ( paymentType?: `ach` | `card`, key? : number, ) => boolean;
  replaceAtKey: ( _pm: IPaymentMethod | null, key : number ) => void;
  addNewPaymentMethod: ( paymentType: `ach` | `card` ) => void;
  lastUsedPaymentMethod: IPaymentMethod | null;
  setLastUsedPaymentMethod : ( _pm : IPaymentMethod ) => void;
  lastTransactionId: string | null;
  setLastTransactionId: ( tId: string ) => void;
}

const usePaymentStore = create<PaymentStore>()( ( set, get ) => ({
  customerProfileId: null,
  setCustomerProfileId: ( _id : string ) => set( () => ({
    customerProfileId: _id
  }) ),
  paymentMethods: [],
  setPaymentMethods: ( _pm: IPaymentMethod[] ) => set( () => ({
    paymentMethods: _pm
  }) ),
  isEmpty: ( paymentType? : `ach` | `card`, key?: number ) => {
    const isEmptyCard = ( pm : CardPaymentMethod ) => !pm?.cardNumber?.length && pm?.cardNumber !== ``
    const isEmptyBank = ( pm : BankPaymentMethod ) => !pm?.accountNumber?.length && pm?.accountNumber !== ``
    if ( !key ) { // if undefined we looks through all payment methods of type for emptiness
      if ( paymentType === `ach` ) return get().paymentMethods.filter( pm => identifyPaymentMethodType( pm ) === `ach` ).every( ( pm : IPaymentMethod ) => isEmptyBank( pm as BankPaymentMethod ) )
      else if ( paymentType === `card` ) return get().paymentMethods.filter( pm => identifyPaymentMethodType( pm ) === `card` ).every( ( pm : IPaymentMethod ) => isEmptyCard( pm as CardPaymentMethod ) )
      else return get().paymentMethods.every( ( pm ) => ( isEmptyBank( pm as BankPaymentMethod ) || isEmptyCard( pm as CardPaymentMethod ) ) )
    }
    const pm = get().paymentMethods[key]

    if ( paymentType === `ach` ) return isEmptyBank( pm as BankPaymentMethod )
    else if ( paymentType === `card` ) return isEmptyCard( pm as CardPaymentMethod )
    else return isEmptyBank( pm as BankPaymentMethod ) || isEmptyCard( pm as CardPaymentMethod )
  },
  replaceAtKey: ( _pm, key ) => {
    const pmArrayCopy = [ ...get().paymentMethods ]
    if ( _pm === null ) { // we want to delete at key if pm is null
      pmArrayCopy.splice( key, 1 )
      set({
        paymentMethods: pmArrayCopy
      })
    } else {
      pmArrayCopy[key] = {
        ..._pm
      }
      set({
        paymentMethods: pmArrayCopy
      })
    }

    if ( get().isEmpty( `card` ) ) get().addNewPaymentMethod( `card` )
    if ( get().isEmpty( `ach` ) ) get().addNewPaymentMethod( `ach` )
  },
  addNewPaymentMethod : ( paymentType : `ach` | `card` ) => {
    const newCard = {
      cardholderName: ``,
      expMonth: ``,
      expYear: ``,
      cardNumber: ``,
      address: ``,
      city: ``,
      state: ``,
      zip: ``,
      ccv: ``
    }
    const newBank = {
      accountNumber: ``,
      routingNumber: ``,
      paymentProfileId: ``,
      nameOnAccount: ``
    }
    const newPayMethod = paymentType === `card` ? newCard : newBank
    set({
      paymentMethods: [ ...get().paymentMethods, newPayMethod ]
    })
  },
  lastUsedPaymentMethod: null,
  setLastUsedPaymentMethod: ( pm : IPaymentMethod ) => set( () => ({
    lastUsedPaymentMethod: pm
  }) ),
  lastTransactionId: null,
  setLastTransactionId: ( tId : string ) => set( () => ({
    lastTransactionId: tId
  }) )
}) )

export default usePaymentStore