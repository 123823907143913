import { InvoiceSelection } from "modules/invoiceSelection"
import { NotFound } from "modules/notFound"
import { Confirmation } from 'modules/confirmation'
import { InvoicePayment, InvoiceDetails } from 'modules/invoice'

export type Route = {
  path: string,
  element: JSX.Element,
  exact?: boolean
}

const routes = [
  {
    path: `/`,
    element: <InvoiceSelection />,
    exact: true
  },
  {
    path: `/invoice-details`,
    element: <InvoiceDetails />
  },
  {
    path: `/invoice-payment`,
    element:  <InvoicePayment />
  },
  {
    path: `/confirm`,
    element: <Confirmation />
  },
  {
    path: `*`,
    element:  <NotFound />
  }
] as Route[]

export default routes