function SectionDivider({text} : {text?: string}): JSX.Element {

  if ( !text?.length ) {
    return <hr className="w-full mt-9"></hr>
  }

  return (
    <div className="flex w-full">
      <div className="w-5/12">
        <hr className="mt-9" />
      </div>
      <div className="w-2/12">
        <p className="text-center mt-6 text-gray-400">{text}</p>
      </div>
      <div className="w-5/12">
        <hr className="mt-9" />
      </div>
    </div>
  )
}

export default SectionDivider