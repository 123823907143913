import { BankPaymentMethod, CardPaymentMethod} from "types"


export const cardPaymentMethodPlaceholder: CardPaymentMethod = {
  cardholderName: `Testy T McTesterson`,
  cardNumber: `1111-1111-1111-5344`,
  expMonth: `03`,
  expYear: `2023`,
  ccv: `123`,
  zip: `28716`,
  state: `NC`,
  city: `Canton`,
  address: `1234 Test Lane`,
  issuer: `Visa`,
  paymentProfileId: ``
}

export const bankPaymentMethodPlaceholder: BankPaymentMethod = {
  accountNumber: ``,
  routingNumber: ``,
  nameOnAccount: `Testy McTesterson`,
  paymentProfileId: ``
}

export const usaShippingRegions = [
  {
    "__typename": `Region`,
    "id": 1,
    "code": `AL`,
    "name": `Alabama`
  },
  {
    "__typename": `Region`,
    "id": 2,
    "code": `AK`,
    "name": `Alaska`
  },
  {
    "__typename": `Region`,
    "id": 4,
    "code": `AZ`,
    "name": `Arizona`
  },
  {
    "__typename": `Region`,
    "id": 5,
    "code": `AR`,
    "name": `Arkansas`
  },
  {
    "__typename": `Region`,
    "id": 12,
    "code": `CA`,
    "name": `California`
  },
  {
    "__typename": `Region`,
    "id": 13,
    "code": `CO`,
    "name": `Colorado`
  },
  {
    "__typename": `Region`,
    "id": 14,
    "code": `CT`,
    "name": `Connecticut`
  },
  {
    "__typename": `Region`,
    "id": 15,
    "code": `DE`,
    "name": `Delaware`
  },
  {
    "__typename": `Region`,
    "id": 16,
    "code": `DC`,
    "name": `District of Columbia`
  },
  {
    "__typename": `Region`,
    "id": 18,
    "code": `FL`,
    "name": `Florida`
  },
  {
    "__typename": `Region`,
    "id": 19,
    "code": `GA`,
    "name": `Georgia`
  },
  {
    "__typename": `Region`,
    "id": 20,
    "code": `HI`,
    "name": `Hawaii`
  },
  {
    "__typename": `Region`,
    "id": 22,
    "code": `ID`,
    "name": `Idaho`
  },
  {
    "__typename": `Region`,
    "id": 23,
    "code": `IL`,
    "name": `Illinois`
  },
  {
    "__typename": `Region`,
    "id": 24,
    "code": `IN`,
    "name": `Indiana`
  },
  {
    "__typename": `Region`,
    "id": 25,
    "code": `IA`,
    "name": `Iowa`
  },
  {
    "__typename": `Region`,
    "id": 26,
    "code": `KS`,
    "name": `Kansas`
  },
  {
    "__typename": `Region`,
    "id": 27,
    "code": `KY`,
    "name": `Kentucky`
  },
  {
    "__typename": `Region`,
    "id": 28,
    "code": `LA`,
    "name": `Louisiana`
  },
  {
    "__typename": `Region`,
    "id": 29,
    "code": `ME`,
    "name": `Maine`
  },
  {
    "__typename": `Region`,
    "id": 31,
    "code": `MD`,
    "name": `Maryland`
  },
  {
    "__typename": `Region`,
    "id": 32,
    "code": `MA`,
    "name": `Massachusetts`
  },
  {
    "__typename": `Region`,
    "id": 33,
    "code": `MI`,
    "name": `Michigan`
  },
  {
    "__typename": `Region`,
    "id": 34,
    "code": `MN`,
    "name": `Minnesota`
  },
  {
    "__typename": `Region`,
    "id": 35,
    "code": `MS`,
    "name": `Mississippi`
  },
  {
    "__typename": `Region`,
    "id": 36,
    "code": `MO`,
    "name": `Missouri`
  },
  {
    "__typename": `Region`,
    "id": 37,
    "code": `MT`,
    "name": `Montana`
  },
  {
    "__typename": `Region`,
    "id": 38,
    "code": `NE`,
    "name": `Nebraska`
  },
  {
    "__typename": `Region`,
    "id": 39,
    "code": `NV`,
    "name": `Nevada`
  },
  {
    "__typename": `Region`,
    "id": 40,
    "code": `NH`,
    "name": `New Hampshire`
  },
  {
    "__typename": `Region`,
    "id": 41,
    "code": `NJ`,
    "name": `New Jersey`
  },
  {
    "__typename": `Region`,
    "id": 42,
    "code": `NM`,
    "name": `New Mexico`
  },
  {
    "__typename": `Region`,
    "id": 43,
    "code": `NY`,
    "name": `New York`
  },
  {
    "__typename": `Region`,
    "id": 44,
    "code": `NC`,
    "name": `North Carolina`
  },
  {
    "__typename": `Region`,
    "id": 45,
    "code": `ND`,
    "name": `North Dakota`
  },
  {
    "__typename": `Region`,
    "id": 47,
    "code": `OH`,
    "name": `Ohio`
  },
  {
    "__typename": `Region`,
    "id": 48,
    "code": `OK`,
    "name": `Oklahoma`
  },
  {
    "__typename": `Region`,
    "id": 49,
    "code": `OR`,
    "name": `Oregon`
  },
  {
    "__typename": `Region`,
    "id": 51,
    "code": `PA`,
    "name": `Pennsylvania`
  },
  {
    "__typename": `Region`,
    "id": 53,
    "code": `RI`,
    "name": `Rhode Island`
  },
  {
    "__typename": `Region`,
    "id": 54,
    "code": `SC`,
    "name": `South Carolina`
  },
  {
    "__typename": `Region`,
    "id": 55,
    "code": `SD`,
    "name": `South Dakota`
  },
  {
    "__typename": `Region`,
    "id": 56,
    "code": `TN`,
    "name": `Tennessee`
  },
  {
    "__typename": `Region`,
    "id": 57,
    "code": `TX`,
    "name": `Texas`
  },
  {
    "__typename": `Region`,
    "id": 58,
    "code": `UT`,
    "name": `Utah`
  },
  {
    "__typename": `Region`,
    "id": 59,
    "code": `VT`,
    "name": `Vermont`
  },
  {
    "__typename": `Region`,
    "id": 61,
    "code": `VA`,
    "name": `Virginia`
  },
  {
    "__typename": `Region`,
    "id": 62,
    "code": `WA`,
    "name": `Washington`
  },
  {
    "__typename": `Region`,
    "id": 63,
    "code": `WV`,
    "name": `West Virginia`
  },
  {
    "__typename": `Region`,
    "id": 64,
    "code": `WI`,
    "name": `Wisconsin`
  },
  {
    "__typename": `Region`,
    "id": 65,
    "code": `WY`,
    "name": `Wyoming`
  }
]
