import { RingLoader } from "components/ringLoader"
import { useEffect } from "react"
import { usePaymentStore } from "store"
import { CardPaymentMethod, IPaymentMethod, identifyPaymentMethodType } from "types/paymentMethods.d"
import CardInformation from "./CardInformation"

function PayByCard() : JSX.Element {

  const { paymentMethods, replaceAtKey, isEmpty, addNewPaymentMethod} = usePaymentStore()

  useEffect( () => {
    if ( isEmpty( `card` ) ) addNewPaymentMethod( `card` )
  }, [ paymentMethods ] )

  if ( isEmpty( `card` ) ) return <RingLoader />

  return (
    <>
      <p className="my-4">{`Pay by card`}</p>
      {paymentMethods?.map( ( paymentMethod, key ) => {
        if ( identifyPaymentMethodType( paymentMethod ) === `ach` ) return null

        return (
          <CardInformation
            // eslint-disable-next-line react/no-array-index-key
            key={( paymentMethod as CardPaymentMethod )?.paymentProfileId ?? key}
            paymentMethod={paymentMethod as CardPaymentMethod}
            setPaymentMethod={( pm : CardPaymentMethod | null ) => replaceAtKey( pm as IPaymentMethod, key )}
            addNewPaymentMethod={() => addNewPaymentMethod( `card` )}
            editView={!( ( paymentMethod as CardPaymentMethod )?.paymentProfileId )}
          /> )
      })}
    </>
  )
}

export default PayByCard