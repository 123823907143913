import { InvoiceData } from 'types'
import create from 'zustand'

export interface InvoiceDataStore {
  invoiceData: InvoiceData[];
  saveInvoiceData: ( _newInvoiceData : InvoiceData[] ) => void;
  getInvoiceByNumber : ( _invoiceNumber : string | undefined ) => InvoiceData | undefined;
  _currentInvoiceNumber: string | undefined; // This is a reference to the currentInvoice from the array above
  getCurrentInvoice: () => InvoiceData | undefined; // This is the public facing variable that will call the getter
  setCurrentInvoice: ( _invoiceNumber : string | undefined ) => void;
  paidInvoices: InvoiceData[];
  markCurrentInvoicePaid: () => void; // This function will add the currentInvoice to the paidInvoices array

  selectMultipleInvoices: () => boolean;
  selectedInvoices: string[];
  addSelectedInvoice: ( _invoiceNumber: string ) => void;
  getSelectedInvoicesSum: ( ) => void;
  resetSelectedInvoices: () => void;
  getIsMaxSelectedInvoices: () => boolean;
}

const useInvoiceDataStore = create<InvoiceDataStore>()( ( set, get ) => ({
  invoiceData: [],
  selectedInvoices: [ ],
  selectMultipleInvoices: () => get()?.selectedInvoices.length > 1,
  getSelectedInvoicesSum: ( ) => {

    const _total = get().selectedInvoices.reduce ( ( total, invoiceNumber ) => {
      const invoice_total = get().getInvoiceByNumber( invoiceNumber.toString() )?.invoice_total

      if ( !invoice_total ) return total

      return Number( ( total + invoice_total ).toFixed( 2 ) )

    }, 0 )

    return _total
  },
  getIsMaxSelectedInvoices: () => get().selectedInvoices.length === 20,
  addSelectedInvoice: ( invoiceNumber ) => {
    const _invoice = get().getInvoiceByNumber( invoiceNumber )
    if ( !_invoice ) return
    const _selected = get().selectedInvoices

    if ( _selected.includes( invoiceNumber ) ) {
      const index = _selected.indexOf( invoiceNumber )
      if ( index > -1 ) {
        _selected.splice( index, 1 )
      }
    } else if ( _selected.length < 20 ){
      _selected.push( invoiceNumber )
    }

    set( () => ({
      selectedInvoices: [ ..._selected ]
    }) )

  },
  resetSelectedInvoices: () => {
    set( () => ({
      selectedInvoices: [ ]
    }) )
  },
  saveInvoiceData: ( newInvoiceData ) => set( () => ({
    invoiceData: newInvoiceData
  }) ),
  getInvoiceByNumber: ( invoiceNumber ) => get().invoiceData?.find( ( iD : InvoiceData ) => iD.invoice_invoiceNumber === invoiceNumber ),
  _currentInvoiceNumber: undefined,
  getCurrentInvoice: () => get().getInvoiceByNumber( get()._currentInvoiceNumber ),
  setCurrentInvoice: ( invoiceNumber ) => set( () => ({
    _currentInvoiceNumber: invoiceNumber
  }) ),
  paidInvoices: [],
  markCurrentInvoicePaid: () => {
    if ( get().selectMultipleInvoices() ) {
      const _selected = get().selectedInvoices
      const _paid = get().paidInvoices

      _selected.forEach( ( invoiceNumber ) => {
        const _invoice = get().getInvoiceByNumber( invoiceNumber )
        if ( !_invoice ) return
        _paid.push( _invoice )
      })

      set( () => ({
        paidInvoices: [ ..._paid ]
      }) )
    } else {
      const _paid = get().paidInvoices
      const _curr = get().getCurrentInvoice()
      if ( !_curr ) return
      _paid.push( _curr )
      set( () => ({
        paidInvoices: [ ..._paid ]
      }) )
    }
  }
}) )

export default useInvoiceDataStore