/**
   * if multiple locations are found invoices need to be grouped under location
   * 1. need to check and see if there are multiple locations
   * 2. if there are multiple locations then we need to group by location
   *
   * */

import { RingLoader } from "components/ringLoader"
import { useEffect, useState } from "react"
import useInvoiceDataStore from "store/invoiceDataStore"
import { InvoiceData } from "types"
import { getHashFromUrl } from "utils"
import { reportToSentry } from "utils/reportToSentry"
import { getInvoiceData } from "./fetch"
import { InvoiceSelectionList } from "components/invoiceSelectionList"
import { useNavigate } from "react-router-dom"

export default function InvoiceSelection(): JSX.Element {

  const { saveInvoiceData, invoiceData, resetSelectedInvoices, multipleInvoices, setCurrentInvoice, selectedInvoices, isMaxSelectedInvoices } = useInvoiceDataStore( ( store ) => ({
    saveInvoiceData: store.saveInvoiceData,
    invoiceData: store.invoiceData,
    resetSelectedInvoices: store.resetSelectedInvoices,
    multipleInvoices: store.selectMultipleInvoices(),
    setCurrentInvoice: store.setCurrentInvoice,
    selectedInvoices: store.selectedInvoices,
    isMaxSelectedInvoices: store.getIsMaxSelectedInvoices()
  }) )
  const [ errorMessage, setErrorMessage ] = useState( `` )
  const [ selectMultipleInvoices, setSelectMultipleInvoices ] = useState( multipleInvoices )
  const [ loading, setLoading ] = useState( true )

  const navigate = useNavigate()

  useEffect( () => {
    if ( getHashFromUrl() === null ) return setErrorMessage( `Session not authorized` )
    getInvoiceData().then( res => {
      if ( res?.meta?.status === `OK` ) {
        saveInvoiceData( res.data )
      }
    })
      .catch( error => {
        setErrorMessage( `An error occurred fetching your invoice(s)` )

        return reportToSentry( new Error( `There was an error getting the invoice data for motif b2b`,
          {
            cause: error
          }
        ) )
      })
      .finally( () => setLoading( false ) )
  }, [] )

  function processInvoiceLocation(): Map<string, Array<InvoiceData>> {
    const locations = new Map<string, Array<InvoiceData>>()
    invoiceData.forEach( ( iD: InvoiceData ) => {
      const locName: string = iD.location_locationName
      const locLocs = locations.get( locName )
      locLocs?.push( iD )
      locations.set( locName, locLocs ?? [ iD ] )
    })

    return locations
  }

  const locationsMap = processInvoiceLocation()

  function handleSelectMultipleInvoices() {
    if ( selectMultipleInvoices ) resetSelectedInvoices()
    setSelectMultipleInvoices( !selectMultipleInvoices )
  }

  function handleNext() {
    if ( multipleInvoices ) navigate( `/invoice-payment`.concat( window.location.search ) )
    else {
      // this logic handles if they choose to select multiple invoices but then only select one
      setCurrentInvoice( selectedInvoices[0] )
      navigate( `/invoice-details`.concat( window.location.search ) )
    }
  }
  if ( errorMessage ) {
    return <p className="text-lg text-error mx-auto w-full">{errorMessage}</p>
  }

  return (
    <div className="mt-4 relative">
      {loading ? <div className="max-w-7xl mx-auto"><RingLoader /></div> :
        <>
          <div className="md:absolute md:right-16 md:-top-16">
            <button
              type="button"
              className="underline text-blue-600"
              data-cy="selectMultipleInvoicesBtn"
              onClick={handleSelectMultipleInvoices}
            >
              { selectMultipleInvoices ? `Select a single invoice to pay` : `Select multiple invoices to pay`}
            </button>
          </div>
          <h1>{selectMultipleInvoices ? `Select your invoices` : `Select your invoice`}</h1>
          {
            [ ...locationsMap.entries() ]?.map( ( locationArray ) => (
              <InvoiceSelectionList
                key={locationArray[0]}
                location={locationArray[0]}
                invoices={locationArray[1]}
                selectMultipleInvoices={selectMultipleInvoices}
              />
            ) )
          }
          {
            selectMultipleInvoices &&
            <div className="max-w-xl text-center mt-4">
              <button
                type="button" className="btn btn-primary w-80" data-cy="nextBtn"
                disabled={selectedInvoices.length === 0}
                onClick={handleNext}
              >{`Next`}</button>
              { isMaxSelectedInvoices && <p className="text-error">{`You can only select up to 20 invoices to pay at a time`}</p>}
            </div>
          }
        </>}
    </div>
  )
}
