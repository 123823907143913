import { moneyFormatter } from "utils/functions"
import { IPaymentMethod } from 'types'
import { charge, deletePaymentMethod, submitPaymentMethod } from 'authnet/api'
import { useInvoiceDataStore, usePaymentStore } from "store"
import { useNavigate, Navigate } from "react-router-dom"
import { useState } from 'react'
import { reportToSentry } from "utils/reportToSentry"


type SubmitPaymentButtonProps = {
  payMethod : IPaymentMethod;
  shouldSavePaymentMethod : boolean;
  onSubmitError : ( _errorString : string ) => void;
  disabled?: boolean;
}

function SubmitPaymentButton({payMethod, shouldSavePaymentMethod, onSubmitError, disabled } : SubmitPaymentButtonProps ): JSX.Element {

  const { invoice, markCurrentInvoicePaid, selectedInvoices, selectMultipleInvoices, selectedInvoicesTotal } = useInvoiceDataStore( ( store ) => ({
    invoice: store.getCurrentInvoice(),
    markCurrentInvoicePaid: store.markCurrentInvoicePaid,
    selectedInvoices: store.selectedInvoices,
    selectMultipleInvoices: store.selectMultipleInvoices(),
    selectedInvoicesTotal: store.getSelectedInvoicesSum()
  }) )

  const { setLastUsedPaymentMethod, setLastTransactionId } = usePaymentStore()

  const navigate = useNavigate()
  const [ loading, setLoading ] = useState( false )

  const invoiceTotal = selectMultipleInvoices ? selectedInvoicesTotal : invoice?.invoice_total
  const invoiceNumbersToCharge = selectMultipleInvoices ? selectedInvoices : invoice?.invoice_invoiceNumber

  async function handlePayment() {
    // if ( !invoice?.invoice_invoiceNumber?.length ) return // TODO: error handle if no invoice is saved in the state
    const customerPaymentProfileId = await handleSavePaymentMethod()
    if ( !customerPaymentProfileId ) return
    const chargeResponse = await charge( customerPaymentProfileId, invoiceTotal, invoiceNumbersToCharge ).catch( error => {
      reportToSentry( new Error( `Error charging payment method`, {
        cause: error
      }) )
    })
    if ( !shouldSavePaymentMethod ) handleRemoveOfSavedPaymentMethod( customerPaymentProfileId ) // If user doesn't want paymentMethod saved, we should delete it from authnet
    if ( chargeResponse?.message === `Successful.` ) {
      markCurrentInvoicePaid()
      setLastUsedPaymentMethod( payMethod )
      setLastTransactionId( chargeResponse?.transactionId )
      navigate( `/confirm${window.location.search}` )
    } else onSubmitError( chargeResponse?.message )
  }

  async function handleSavePaymentMethod() {
    if ( payMethod?.paymentProfileId ) return payMethod.paymentProfileId
    const paymentMethodSubmitResponse = await submitPaymentMethod( payMethod ).catch( error => {
      onSubmitError( `Error Saving Payment Method:`.concat( error ) )

      return reportToSentry( new Error( `There was an error saving the payment method`, {
        cause: error
      }) )
    })

    if ( paymentMethodSubmitResponse?.messages?.resultCode === `Error` ) {
      return onSubmitError( `Error Saving Payment Method: `.concat( paymentMethodSubmitResponse?.messages?.message?.map( ( error : {text : string}) => `${error?.text} ` ) ) )
    }

    return paymentMethodSubmitResponse?.customerPaymentProfileId
  }

  function handleRemoveOfSavedPaymentMethod( customerPaymentProfileId : string ) {
    deletePaymentMethod( customerPaymentProfileId ).catch( ( error ) => {
      reportToSentry( new Error( `There was an error deleting the payment method`, {
        cause: error
      }) )
    })
  }

  if ( invoice === undefined && selectedInvoices === undefined ) return <Navigate to={`/${window.location.search}`} replace />

  return (
    <div className="my-4">
      <button
        type="button"
        disabled={disabled}
        onClick={() => { setLoading( true ); handlePayment().then( () => setLoading( false ) ) }}
        className="btn btn-primary w-full"
        data-cy="total"
      >
        {
          <>
            {loading ? `Submitting Payment...` : `Pay ${moneyFormatter.format( invoiceTotal || 0 )}`}
          </>
        }
      </button>
    </div>
  )
}

export default SubmitPaymentButton