/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { InvoiceData, LineItemType } from 'types'
import { LineItem } from 'components/lineItem'
import { formatDate, moneyFormatter } from 'utils'
import PaymentBreakdown from './PaymentBreakdown'

function ItemBreakdown({ invoice }: { invoice: InvoiceData }) : JSX.Element {


  return (
    <div className="p-8 rounded-lg border-2 border-lightslategray w-full max-w-xl mx-auto mb-4">
      <div className="border-b border-lightslategray mb-7">
        <div>
          {invoice?.invoice_poNumber && <p className="font-bold">{`PO ${invoice?.invoice_poNumber}`}</p>}
          <p className="text-slategray font-light">{`Invoice #${invoice.invoice_invoiceNumber}`}</p>
          <p className="text-slategray font-light">{`${formatDate( Date() )}`}</p>
        </div>
        <div className="my-5">
          <p className="font-bold">{`Bill To`}</p>
          <p className="text-slategray font-light">{invoice.location_locationName}</p>
        </div>
      </div>
      <div className="flex flex-col gap-5">
        {/* {
          invoice.line_items?.length > 0 &&
          <>
            {
              invoice.line_items.map( ( item: LineItemType ) => {
                return <LineItem key={item.item_id} item={item} />
              })
            }
          </>
        } */}
      </div>
      <div className="mt-10 mb-5 flex justify-between">
        <p className="font-bold">{`SHIPPING`}</p>
        <p className="text-slategray">{`${ moneyFormatter.format( invoice.invoice_shippingCharge )}`}</p>
      </div>
      <div className="my-5 flex justify-between">
        <p className="font-bold">{`Subtotal`}</p>
        <p className="text-slategray">{`${ moneyFormatter.format( invoice.invoice_total )}`}</p>
      </div>
      <div className="py-10 mb-10 flex justify-between border-b border-lightslategray">
        <p className="font-bold text-xl">{`Total`}</p>
        <p className="font-bold text-lg">{`${ moneyFormatter.format( invoice.invoice_total )}`}</p>
      </div>

      <div className="mb-5">
        <PaymentBreakdown />
        <div className="flex justify-between">
          <div>
            <p className="text-sm mt-3 text-slategray">{`Paid on ${formatDate( Date() )}`}</p>
          </div>
          <p className="text-slategray">{`${ moneyFormatter.format( invoice.invoice_total )}`}</p>
        </div>
      </div>
    </div>
  )
}

export default ItemBreakdown