import React from 'react'

export const NotFound = () => {
  return (
    <div className="text-slategray" >
      <div className="md:p-40 sm:p-8">
        <div className="flex font-opensans font-semibold flex-wrap md:m-0 sm:m-auto w-full sm:w-8/12 md:text-7xl sm:text-4xl md:justify-start sm:justify-center py-8">
          <div className="mr-2">{`404.`}</div>
          <div>{`Page not found.`}</div>
        </div>
        <div className="md:text-3xl font-opensans sm:text-lg md:text-left sm:text-center font-light leading-relaxed">
          <p>{`Unfortunately, this page does not exist.`}</p>
        </div>
      </div>
    </div>
  )
}

export default NotFound
