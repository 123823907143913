import BankInformation from 'components/payByBank/BankInformation'
import CardInformation from 'components/payByCard/CardInformation'
import { BankPaymentMethod, CardPaymentMethod, identifyPaymentMethodType } from 'types'
import { usePaymentStore } from 'store'


export default function PaymentBreakdown() {

  const { lastUsedPaymentMethod } = usePaymentStore()
  const paymentMethodType = identifyPaymentMethodType( lastUsedPaymentMethod )

  return (
    <>
      <p className="mb-5">{`Invoice paid with`}</p>
      {( paymentMethodType === `card` ) ? (
        <CardInformation
          paymentMethod={( lastUsedPaymentMethod as CardPaymentMethod )} setPaymentMethod={() => { return }}
          readOnly addNewPaymentMethod={() => { return }}
        />
      ) : (
        <BankInformation
          paymentMethod={( lastUsedPaymentMethod as BankPaymentMethod )} setPaymentMethod={() => { return }}
          readOnly addNewPaymentMethod={() => { return }}
        />
      )
      }
    </>
  )
}