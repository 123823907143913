import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div.attrs({
  className: `my-0 mx-auto relative w-30`
})`
  & > div {
    border: 10px solid #5b6770;
    border-radius: 100%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  .two {
    border: 5px solid #5b6770;
    border-top: 5px solid #a9dbd2;
    width: 95px;
    height: 95px;
    animation: spin 4s linear infinite;
  }
  .three {
    top: 30px;
    left: 30px;
    border: 4px solid #E9F6F4;
    border-top: 4px solid white;
    width: 60px;
    height: 60px;
    animation: spin-reverse 2s linear infinite;
  }
  .four {
    border: 4px solid #00838a;
    border-top: 4px solid #a9dbd2;
    width: 40px;
    height: 40px;
    animation: spin 1.25s linear infinite;
  }
  .five {
    border: 4px solid #00838a;
    border-top: 4px solid #a9dbd2;
    width: 80px;
    height: 80px;
    animation: spin-reverse 4.25s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spin-reverse {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
`

function RingLoader() {
  return (
    <Wrapper>
      <div />
      <div className="absolute top-3 left-3 two"></div>
      <div className="absolute three"></div>
      <div className="absolute top-10 left-10 w-10 h-10 four"></div>
      <div className="absolute top-5 left-5 five"></div>
    </Wrapper>
  )
}

export default RingLoader
