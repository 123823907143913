import { CheckboxInput } from "components/forms/components"

type SavePaymentMethodCheckboxInputType = {
  payMethodType: `card` | `ach`
  value: boolean,
  onChange: ( _e : React.ChangeEvent<HTMLInputElement> ) => void
}

export default function SavePaymentMethodCheckboxInput({payMethodType, value, onChange} : SavePaymentMethodCheckboxInputType ) : JSX.Element {
  return (
    <CheckboxInput
      key={`save`.concat( payMethodType, `Payment` )}
      name="savePaymentMethod" value={value} onChange={onChange}
      label="Save this payment method" id={`save`.concat( payMethodType, `Payment` )} reference={undefined}
    />
  )
}