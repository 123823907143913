
// Regex error must be added for input to properly validate. Re-use first! Don't change any of the key/value pairs above unless instructed by form SOP coordinator.
// @NOTE whatever you do don't change these keys!!
export const regexErrors = {
  memberID: `Member ID cannot contain special characters or spaces. Please use valid letters, numbers and dashes. Must be more than 3 characters`,
  secondaryMemberID: `Member ID cannot contain special characters or spaces. Please use valid letters, numbers and dashes. Must be more than 3 characters`,
  email: `Enter a valid email`,
  dd: `Please enter a day between 01 - 31`,
  card_number: `Enter a valid card number`,
  expmm: `Please enter a month between 01 - 12`,
  expyyyy: `Enter valid expiration year`,
  ccv: `Enter a valid ccv`,
  first_name: `Enter a valid first name. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 1 and less than 22 letters`,
  last_name: `Enter a valid last name. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 1 and less than 22 letters`,
  name: `Enter a valid name. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 1 and less than 22 letters`,
  phone: `Enter a valid phone number`,
  dob_yyyy: `Please enter a valid 4 digit year`,
  zip: `Zip code must contain 5 numbers`,
  address: `Special characters are not accepted besides dashes and periods. Please use valid letters (a-z or A-Z) numbers (0-9) or spaces. Must be more than 1 letter`,
  city: `Special characters are not accepted besides dashes and periods. Please use valid letters (a-z or A-Z) or spaces. Must be more than 1 letter`,
  memberId: `Must be longer than 3 characters. No special characters allowed except for dashes (-)`,
  dueDateYYYY: `Please enter a valid 4 digit year within 1 year of the current year`,
  weight: `Please enter a weight between 1 and 999 lbs.`,
  date_of_birth: `Please enter a valid date by month (01 - 12), day (01 - 31), and year within the past 120 years`,
  due_date: `Please enter a valid date by month (01 - 12), day (01 - 31). Must be prior to 9 months from current date and no later than 1 year in the past.`,
  state: `Please enter a state`,
  insuranceType: `Please select insurance type.`,
  secondaryInsuranceType: `Please select insurance type.`,
  howDidYouHearAboutUs: `Please select how you heard about us.`,
  password: `Your password must be at least 8 characters, contain lower case, upper case, and at least one number.`,
  passwordConfirmation: `Your passwords must match.`,
  terms: `You must accept the terms & conditions to continue`,
  accountNumber: `Please enter a valid bank account number`,
  routingNumber: `Please enter a valid bank routing number`
}

export const months = [
  {
    value: `01`,
    text: `January`
  },
  {
    value: `02`,
    text: `Febuary`
  },
  {
    value: `03`,
    text: `March`
  },
  {
    value: `04`,
    text: `April`
  },
  {
    value: `05`,
    text: `May`
  },
  {
    value: `06`,
    text: `June`
  },
  {
    value: `07`,
    text: `July`
  },
  {
    value: `08`,
    text: `August`
  },
  {
    value: `09`,
    text: `September`
  },
  {
    value: `10`,
    text: `October`
  },
  {
    value: `11`,
    text: `November`
  },
  {
    value: `12`,
    text: `December`
  }
]