import { identifyPaymentMethodType } from 'types/paymentMethods.d'
import { getHashFromUrl } from 'utils/functions'
import { reportToSentry } from 'utils/reportToSentry'

export const getPaymentProfiles = async ( signal : AbortSignal ) => {
  const hash = getHashFromUrl()

  if ( !hash ) reportToSentry( new Error( `No hash in url for motif b2b` ) )

  const auth = `PatientHash ${hash}`
  const response = await fetch( `${process.env.REACT_APP_AUTHNET_PAYMENTS_URL}/v2/profile`, {
    headers: {
      'Authorization': auth,
      'Content-Type': `application/json`,
      'Access-Control-Allow-Origin': `*`
    },
    signal
  })

  return await response.json()
}


/**
   * Makes a request to charge the paymentProfile
   */
export const charge = async ( paymentProfileId : string, amount : string | number | void, invoiceNumbersToCharge : string | string[] | undefined ) => {
  const hash = getHashFromUrl()
  const auth = `PatientHash ${hash}`
  const body = JSON.stringify({
    amount,
    source: process.env.REACT_APP_ENVIRONMENT,
    ...( ( typeof invoiceNumbersToCharge === `string` ) ? {
      invoiceNumber: invoiceNumbersToCharge
    } : {
      invoiceNumbers: invoiceNumbersToCharge
    })
  })

  const response = await fetch( `${process.env.REACT_APP_AUTHNET_PAYMENTS_URL}/v2/payment-profile/${paymentProfileId}/charge`, {
    method: `POST`,
    headers: {
      Authorization: auth
    },
    body: body
  })
    .catch( error => {
      reportToSentry( new Error( `Failed to make payment`, {
        cause: error
      }), {
        details: body
      })
    })

  const paymentData = await response?.json()

  const authnet_message = paymentData?.messages?.message[0]?.text ?? ``

  if ( authnet_message !== `Successful.` ) {
    reportToSentry( new Error( `Failed to make payment` ), {
      authnet_message,
      authnet_response: JSON.stringify( paymentData ?? `Authnet Response is undefined` )
    })
  }

  return {
    message: authnet_message,
    transactionId: paymentData?.transactionResponse?.transId
  }
}

export const submitPaymentMethod = async ( paymentMethod : any ) => {
  const hash = getHashFromUrl()
  const auth =`PatientHash ${hash}`
  const paymentType = identifyPaymentMethodType( paymentMethod )

  const getNames = ( fullName : string ) => {
    const names = fullName.split( ` ` )
    if ( names.length === 1 ) return {
      firstName: names[0]
    }
    if ( names.length > 1 ) return { // we have multiple names, going to take the first and add all other names to last
      firstName: names[0],
      lastName: names.slice( 1 ).join( ` ` )
    }

    return {}
  }

  if ( !paymentType ) throw new Error( `Invalid paymentMethod passed` )
  const paymentMethodBody = ( paymentType === `card` ) ? {
    cardNumber: ( paymentMethod?.cardNumber ?? `` ).replace( /-/g, `` ),
    expirationDate: `20${paymentMethod.expYear}-${paymentMethod.expMonth}`,
    cardCode: paymentMethod.ccv,
    ...( getNames( paymentMethod.cardholderName ) ),
    address: paymentMethod.address,
    city: paymentMethod.city,
    state: paymentMethod.state,
    zip: paymentMethod.zip
  } : {
    routingNumber: paymentMethod.routingNumber,
    accountNumber: paymentMethod.accountNumber,
    nameOnAccount: paymentMethod.nameOnAccount,
    ...( getNames( paymentMethod.nameOnAccount ) )
  }

  const response = await fetch( `${process.env.REACT_APP_AUTHNET_PAYMENTS_URL}/v2/payment-profile`, {
    method: `POST`,
    headers: {
      Authorization: auth
    },
    body: JSON.stringify( paymentMethodBody )
  })

  return await response.json()
}

export const deletePaymentMethod = async ( paymentProfileId : string ) => {

  const hash = getHashFromUrl()
  const auth = `PatientHash ${hash}`

  const response = await fetch( `${process.env.REACT_APP_AUTHNET_PAYMENTS_URL}/v2/payment-profile/${paymentProfileId}`, {
    method: `DELETE`,
    headers: {
      Authorization: auth
    }
  })

  return await response.json()
}