import { useInvoiceDataStore } from 'store'
import { moneyFormatter } from 'utils'

export default function InvoiceHeader() {
  const { invoice, selectMultipleInvoices, selectedInvoicesTotal } = useInvoiceDataStore( ( store ) => ({
    invoice: store.getCurrentInvoice(),
    selectMultipleInvoices: store.selectMultipleInvoices(),
    selectedInvoicesTotal: store.getSelectedInvoicesSum()
  }) )

  if ( !invoice && !selectMultipleInvoices ) return null

  const invoiceTotal = selectMultipleInvoices ? selectedInvoicesTotal : invoice?.invoice_total


  return <h2>{`Motif Medical, LLC sent you an invoice for ${moneyFormatter.format( invoiceTotal || 0 )}`}</h2>
}