import { LineItemType } from 'types'
import { LineItem } from 'components/lineItem'
import { moneyFormatter } from 'utils'

function InvoiceItemDisplay( invoice: any ) : JSX.Element {

  return (
    <div className="p-8 rounded-lg border-2 border-lightslategray w-full max-w-xl">
      <div className="border-b border-lightslategray mb-7">
        <div>
          {invoice?.invoice[0]?.invoice_poNumber ? <p className="font-bold">{`PO ${invoice?.invoice[0]?.invoice_poNumber}`}</p> : ``}
          <p className="text-slategray font-light">{`Invoice #${invoice?.invoice[0]?.invoice_invoiceNumber}`}</p>
          <p className="text-slategray font-light">{`Account #${invoice?.invoice[0]?.location_locationID}`}</p>
          <p className="text-slategray font-light">{invoice?.invoice[0]?.invoice_paid_date}</p>
        </div>
        <div className="my-5">
          <p className="font-bold">{`Bill To`}</p>
          <p className="text-slategray font-light">{invoice?.invoice[0]?.location_locationName}</p>
          <p className="text-slategray font-light">{invoice?.invoice[0]?.location_street}</p>
          <div className="flex">
            <p className="text-slategray font-light">{invoice?.invoice[0]?.location_city}</p>
            <p className="text-slategray font-light mx-2">{invoice?.invoice[0]?.location_state}</p>
            <p className="text-slategray font-light">{invoice?.invoice[0]?.location_zip}</p>
          </div>
          {invoice?.invoice[0]?.email_address ? <p className="font-bold">{`Email ${invoice?.invoice[0]?.email_address}`}</p> : ``}
        </div>
      </div>
      <div className="flex flex-col gap-5">
        {
          invoice.invoice.length > 0 &&
          <>
            {
              invoice.invoice.map( ( item: LineItemType ) => {
                return <LineItem key={item.item_sku} item={item} />
              })
            }
          </>
        }
      </div>
      <div className="mt-10 mb-5 flex justify-between">
        <p className="font-bold">{`SHIPPING`}</p>
        <p className="text-slategray">{`${ moneyFormatter.format( invoice?.invoice[0]?.invoice_shippingCharge )}`}</p>
      </div>
      <div className="my-5 flex justify-between">
        <p className="font-bold">{`Subtotal`}</p>
        <p className="text-slategray">{`${ moneyFormatter.format( invoice?.invoice[0]?.invoice_subtotal )}`}</p>
      </div>
      <div className="py-10 mb-10 flex justify-between border-b border-lightslategray">
        <p className="font-bold text-xl">{`Total`}</p>
        <p data-cy="total" className="font-bold text-lg">{`${ moneyFormatter.format( invoice?.invoice[0]?.invoice_total )}`}</p>
      </div>
    </div>
  )
}

export default InvoiceItemDisplay