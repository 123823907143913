import PayByCard from "components/payByCard"
import PayByBank from "components/payByBank"
import { SectionDivider } from "components/sectionDivider"
import { useEffect, useState } from "react"
import { getPaymentProfiles } from "authnet/api"
import InvoiceHeader from "./InvoiceHeader"
import { useNavigate } from "react-router-dom"
import { useInvoiceDataStore, usePaymentStore } from "store"
import { reportToSentry } from "utils/reportToSentry"
import { serializeAuthnetPaymentProfileResponse } from "types/paymentMethods.d"
import { RingLoader } from "components/ringLoader"

export default function InvoicePayment(): JSX.Element {

  const navigate = useNavigate()

  const { setCustomerProfileId, setPaymentMethods } = usePaymentStore()
  const { selectMultipleInvoices } = useInvoiceDataStore( ( store ) => ({
    selectMultipleInvoices: store.selectMultipleInvoices()
  }) )
  const [ loading, setLoading ] = useState( true )

  useEffect( () => {
    const controller = new AbortController()
    getPaymentProfiles( controller.signal ).then( ( response ) => {
      if ( !controller.signal.aborted ) {
        const savedPaymentMethods = serializeAuthnetPaymentProfileResponse( response?.profile?.paymentProfiles )
        if ( savedPaymentMethods?.length ) setPaymentMethods( savedPaymentMethods )
        setCustomerProfileId( response?.profile?.customerProfileId )
      }
    })
      .catch( ( error ) => reportToSentry( new Error( `There was an error getting payment profiles` ), {
        cause: error
      }) )
      .finally( () => setLoading( false ) )

    return () => controller.abort()
  }, [] )

  function handleGoBack() {
    if ( selectMultipleInvoices ) return navigate( `/${window.location.search}` )
    navigate( `/invoice-details${window.location.search}` )
  }

  return (
    <div className="max-w-xl">
      <InvoiceHeader />
      {loading ? <RingLoader /> : (
        <>
          <div className="px-8 py-4 rounded-lg border-2 border-lightslategray mt-4">
            <PayByCard />
            <SectionDivider text="OR" />
            <PayByBank />
          </div>
          <div className="mx-8 my-2">
            <button className="btn btn-secondary w-full " onClick={handleGoBack}>{`Go Back`}</button>
          </div>
        </>
      )
      }
    </div>
  )
}

