import { Layout } from 'modules/layout'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import routes, { Route as RouteType } from './routes'

function App() : JSX.Element {

  return (
    <Router>
      <Layout>
        <Routes>
          {routes.map( ( route : RouteType ) => {
            return (
              <Route key={route.path} {...route} />
            )
          })}
        </Routes>
      </Layout>
    </Router>
  )
}

export default App
