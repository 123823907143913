import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import App from './App'


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  Integrations: [ new BrowserTracing() ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

const root = ReactDOM.createRoot( document.getElementById( `root` ) )
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
