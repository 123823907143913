export type PaymentMethodButtonGroupProps = {onNew: () => void; onDelete: () => void}
export default function PaymentMethodButtonGroup({onNew, onDelete} : PaymentMethodButtonGroupProps ) {
  return (
    <div className="flex justify-evenly flex-wrap">
      <button className="btn btn-secondary sm:w-80 md:w-56" onClick={onNew}>
        {`Add new`}
      </button>
      <button className="btn btn-danger sm:w-80 md:w-56" onClick={onDelete}>
        {`Delete`}
      </button>
    </div>
  )
}