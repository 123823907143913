import { Formatters, getExpiryYearList } from "components/forms/components"
import { months } from "components/forms/constants"
import { SelectInput, TextInput } from "components/forms/library"
import { RefObject, useRef, useState, useMemo } from "react"
import { CardPaymentMethod } from "types"
import { getValidationHelper } from "components/forms/validation"
import { SubmitPaymentButton } from "components/customButtons"
import { SavePaymentMethodCheckboxInput } from "components/savePaymentMethod"
import { usaShippingRegions } from "utils"

export type EditCardInformationProps = {
  payMethod: CardPaymentMethod,
  setPayMethod: ( _pM : CardPaymentMethod ) => void
}

export default function EditCardInformation({payMethod, setPayMethod} : EditCardInformationProps ) : JSX.Element {

  const [ errors, setErrors ] = useState<Record<string, string>>({
    cardholderName: ``,
    cardNumber: ``,
    address: ``,
    city: ``,
    state:  ``,
    zip: ``,
    ccv: ``
  })
  const [ currentFocus, setCurrentFocus ] = useState( `` )
  const [ shouldSavePaymentMethod, setShouldSavePaymentMethod ] = useState( false )
  const [ submitPaymentError, setSubmitPaymentError ] = useState( `` )


  const inputRefs = {
    cardholderName: useRef<HTMLInputElement>( null ),
    cardNumber: useRef<HTMLInputElement>( null ),
    expMonth: useRef<HTMLSelectElement>( null ),
    expYear: useRef<HTMLSelectElement>( null ),
    address: useRef<HTMLInputElement>( null ),
    city: useRef<HTMLInputElement>( null ),
    state: useRef<HTMLSelectElement>( null ),
    zip: useRef<HTMLInputElement>( null ),
    ccv: useRef<HTMLInputElement>( null )
  } as Record<string, RefObject<HTMLInputElement | HTMLSelectElement>>

  function handleInputChange( event : React.ChangeEvent<HTMLInputElement> ) {
    const { name, value } = event.currentTarget
    setPayMethod({
      ...payMethod,
      [name]: value
    })

    const { errorMessage, validator } = getValidationHelper( name )
    const _errors = {
      ...errors
    }
    _errors[ name ] = validator( value ) ? `` : errorMessage
    setErrors( _errors )
  }

  function handleFocus( event : React.ChangeEvent<HTMLInputElement> ) {
    setCurrentFocus( event.target.name )
  }

  function handleSavePaymentMethod() {
    setShouldSavePaymentMethod( !shouldSavePaymentMethod )
  }

  const inputHandlers = {
    onChange: handleInputChange,
    onFocus: handleFocus
  }

  const formComplete = useMemo( () => Object.values( inputRefs )?.every( ( input ) => Boolean( input?.current?.value?.length ) ), [ errors ] )

  return (
    <>
      <p className="text-sm text-gray-500 my-2">{`Visa, Mastercard, Amex, and Discover cards accepted.`}</p>
      <TextInput
        id={`cardholderName`}
        name="cardholderName"
        placeholder="Name on card"
        value={payMethod?.cardholderName ?? ``}
        errorMessage={currentFocus !== `cardholderName` && errors.cardholderName}
        required
        reference={inputRefs.cardholderName as RefObject<HTMLInputElement>}
        {...inputHandlers}
      />
      <div>
        <TextInput
          id="cardNumber"
          name="cardNumber"
          placeholder="Card Number"
          type="tel"
          value={payMethod?.cardNumber ?? ``}
          formatter={{
            function: Formatters.creditCard
          }}
          errorMessage={currentFocus !== `cardNumber` ? errors.cardNumber : ``}
          reference={inputRefs.cardNumber as RefObject<HTMLInputElement>}
          required
          {...inputHandlers}
        />
      </div>
      <div className="flex justify-start gap-2">
        <div className="flex gap-2 w-6/12">
          <SelectInput
            id="expMonth"
            name="expMonth"
            value={payMethod?.expMonth ?? ``}
            errorMessage={currentFocus !== `expMonth` ? errors.expMonth : ``}
            reference={inputRefs.expMonth as RefObject<HTMLSelectElement>}
            required
            {...inputHandlers}
          >
            <option value="" hidden>{`MM`}</option>
            {months.map( month => <option key={month.value} value={month.value}>{month.text}</option> )}
          </SelectInput>
          <SelectInput
            id="expYear"
            name="expYear"
            value={payMethod?.expYear ?? ``}
            errorMessage={currentFocus !== `expYear` ? errors.expYear : ``}
            reference={inputRefs.expYear as RefObject<HTMLSelectElement>}
            required
            {...inputHandlers}
          >
            <option value="" hidden>{`YY`}</option>
            {getExpiryYearList().map( expiry => <option key={expiry.YY} value={expiry.YY}>{expiry.YYYY}</option> )}
          </SelectInput>
        </div>
        <div className="flex gap-2 w-6/12">
          <TextInput
            id="ccv"
            name="ccv"
            placeholder="CCV"
            value={payMethod?.ccv ?? ``}
            errorMessage={currentFocus !== `ccv` && errors.ccv}
            reference={inputRefs.ccv as RefObject<HTMLInputElement>}
            required
            {...inputHandlers}
          />
          <TextInput
            id="zip"
            name="zip"
            placeholder="Zip Code"
            value={payMethod?.zip ?? ``}
            errorMessage={currentFocus !== `zip` && errors.zip}
            reference={inputRefs.zip as RefObject<HTMLInputElement>}
            formatter={{
              function: Formatters.zipCode
            }}
            required
            {...inputHandlers}
          />
        </div>
      </div>
      <TextInput
        id="address"
        name="address"
        placeholder="Billing Street Address"
        value={payMethod?.address ?? ``}
        errorMessage={currentFocus !== `address` && errors.address}
        reference={inputRefs.address as RefObject<HTMLInputElement>}
        required
        {...inputHandlers}
      />
      <div className="flex gap-2">
        <TextInput
          id="city"
          name="city"
          placeholder="Billing City"
          value={payMethod?.city ?? ``}
          errorMessage={currentFocus !== `city` && errors.city}
          reference={inputRefs.city as RefObject<HTMLInputElement>}
          required
          {...inputHandlers}
        />
        <SelectInput
          name="state"
          className="input md:max-w-xl"
          value={payMethod?.state ?? ``}
          errorMessage={currentFocus !== `state` && errors.state}
          reference={inputRefs.state as RefObject<HTMLSelectElement>}
          required
          {...inputHandlers}
        >
          <option value="" disabled hidden>{`Select a State`}</option>
          {usaShippingRegions.map( region => {
            return <option key={region.id} value={region.code}>{region.name}</option>
          })}
        </SelectInput>
      </div>

      <SavePaymentMethodCheckboxInput
        payMethodType="card"
        value={shouldSavePaymentMethod} onChange={handleSavePaymentMethod}
      />
      <p className="text-error my-2">{submitPaymentError}</p>
      <SubmitPaymentButton
        disabled={!formComplete}
        payMethod={payMethod}
        shouldSavePaymentMethod={shouldSavePaymentMethod}
        onSubmitError={( errorMessage ) => setSubmitPaymentError( errorMessage )}
      />
    </>
  )
}