import { InvoiceItemDisplay } from "components/invoiceItemDisplay"
import { RingLoader } from "components/ringLoader"
import useInvoiceDataStore from "store/invoiceDataStore"
import { InvoiceData } from "types"

export function ItemDetail(): JSX.Element {
  const invoice = useInvoiceDataStore( ( store ) => store.getCurrentInvoice() )
  const { invoiceData } = useInvoiceDataStore()

  const selectedInvoiceNumber = invoice?.invoice_invoiceNumber

  function processInvoiceLineItems(): Map<string, Array<InvoiceData>> {
    const invoices = new Map<string, Array<InvoiceData>>()
    invoiceData.forEach( ( iD: InvoiceData ) => {
      const invoiceNumber: string = iD.invoice_invoiceNumber
      if ( invoiceNumber === selectedInvoiceNumber ) {
        const invInvs = invoices.get( invoiceNumber )
        invInvs?.push( iD )
        invoices.set( invoiceNumber, invInvs ?? [ iD ] )
      }
    })

    return invoices
  }

  const invoiceMap = processInvoiceLineItems()

  if ( !invoice || !invoiceData.length ) { return <RingLoader /> }

  return (
    <div className="flex">
      {
        [ ...invoiceMap.entries() ]?.map( ( InvoiceArray ) => (
          <InvoiceItemDisplay
            key={InvoiceArray[0]}
            invoice={InvoiceArray[1]}
          />
        ) )
      }
    </div>
  )
}

export default ItemDetail