import * as React from 'react'
import { getUniqueIdPrefix } from 'components/forms/utils'
import { TextInputProps } from '../types'

/** Error handling works in two ways
 * a) pass a error function and message through props.validator to work on onChange
 * b) pass a the error message to be displayed through the prop error */
const TextInput = ({
  name,
  value,
  type = `text`,
  required = false,
  label,
  className = `input`,
  errorClassName = `input-error`,
  labelClassName = `input-label`,
  errorMessageClassName = `text-error px-3`,
  asteriskClassName = `font-semibold text-error pl-2`,
  errorMessage = ``,
  formatter,
  validator,
  reference,
  onChange,
  onBlur,
  disabled = false,
  id,
  placeholder,
  ...rest
} : TextInputProps ) => {
  const [ validationError, setValidationError ] = React.useState( errorMessage )
  const uniqueId = id || `${getUniqueIdPrefix()}-${name}`

  React.useEffect( () => {
    setValidationError( errorMessage )
  }, [ errorMessage ] )

  const onHandleChange = ( event : React.ChangeEvent<HTMLInputElement> ) => {
    if ( formatter?.function && !formatter?.onBlur ) {
      event.currentTarget.value = formatter.function( event.currentTarget.value )
    }

    if ( validator && validator.function && !validator.function( event.currentTarget.value ) ) {
      setValidationError( validator.failureMessage || `Invalid Entry` )
    } else {
      setValidationError( `` )
    }

    onChange( event )
  }

  const onHandleBlur = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    if ( formatter?.function && formatter?.onBlur ) {
      event.currentTarget.value = formatter.function( event.currentTarget.value )
      onChange( event )
    }
    onBlur && onBlur( event )
  }

  // sets error, disabled, or normal className on input
  const inputClassName = ( validationError ) ? errorClassName : (
    ( disabled ) ? `input-disabled ${className}` : className
  )

  return (
    <div>
      {label && (
        <label htmlFor={uniqueId} className={labelClassName}>
          {label}
          {required && <span className={asteriskClassName}>{`*`}</span>}
        </label>
      )}

      <input
        id={uniqueId}
        name={name}
        type={type}
        value={value}
        onChange={onHandleChange}
        className={inputClassName}
        required={required}
        ref={reference}
        disabled={disabled}
        onBlur={onHandleBlur}
        placeholder={placeholder}
        {...rest}
      />

      {validationError && <p className={errorMessageClassName}>{validationError}</p>}
    </div>
  )
}

export default TextInput
