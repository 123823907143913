import Header from 'components/header/Header'
import React, { ReactNode } from 'react'

function Layout({ children } : { children: ReactNode }) {
  return (
    <div className="sm:p-4 md:p-10 max-w-3xl mx-auto">
      <Header />
      {children}
    </div>
  )
}

export default Layout