import { InvoiceData } from 'types'
import { getHashFromUrl } from 'utils'

export type InvoiceDataResponse = {
  data : InvoiceData[],
  meta: {
    status: string
  }
}

const { REACT_APP_AOB_PAYMENTS_URL } = process.env

export async function getInvoiceData(): Promise<InvoiceDataResponse> {
  const hash = getHashFromUrl()
  const url =`${REACT_APP_AOB_PAYMENTS_URL}/motif-invoice/${hash}`
  const response = await fetch( url )

  return await response?.json()
}