import { ItemDetail } from "./"
import useInvoiceDataStore from "store/invoiceDataStore"
import { formatDate } from "utils"
import { useNavigate } from "react-router-dom"
import { DetailLineItem } from "components/DetailLineItem"

export default function InvoiceDetails() : JSX.Element {
  const { invoiceData, resetSelectedInvoices } = useInvoiceDataStore( ( store ) => ({
    invoiceData: store.getCurrentInvoice(),
    resetSelectedInvoices: store.resetSelectedInvoices
  }) )
  const navigate = useNavigate()

  function handleGoBack() {
    resetSelectedInvoices()

    return navigate( `/`.concat( window.location.search ) )
  }

  return (
    <div className="sm:p-4 md:p-8 rounded-lg md:border-2 border-lightslategray mt-4">
      <button onClick={() => window.print()} className="btn btn-primary print:hidden">{`Download PDF`}</button>
      <div className="flex justify-between mb-4">
        <div className="my-4">
          {invoiceData?.invoice_invoiceDate ? <p className="font-light">{`Invoice Date ${formatDate( invoiceData?.invoice_invoiceDate )}`}</p> : ``}
          {invoiceData?.invoice_dueDate ? <p className="font-light">{`Invoice Due ${formatDate( invoiceData?.invoice_dueDate )}`}</p> : ``}
        </div>
        <div className="mr-12">
          <p>{`Motif Medical, LLC`}</p>
          <p>{`3165 Sweeten Creek Rd`}</p>
          <p>{`Asheville, NC 28803`}</p>
          <p>{`844-272-8390`}</p>
        </div>
      </div>
      <div>
        <ItemDetail />
      </div>
      <div className="w-1/2 mt-4">
        <DetailLineItem title="Subtotal" amount={invoiceData?.invoice_subtotal} />
        <DetailLineItem title="Shipping" amount={invoiceData?.invoice_shippingCharge} />
        <DetailLineItem title="Admin fee" amount={invoiceData?.invoice_adminFee} />
        <DetailLineItem title="Discount" amount={invoiceData?.invoice_discountAmount} />
        <DetailLineItem title="Total" titleClassName="font-bold" amount={invoiceData?.invoice_total} />
      </div>
      <div className="flex w-full flex-wrap">
        <button className="btn btn-primary w-80 print:hidden" data-cy="payInvoiceBtn" onClick={() => navigate( `/invoice-payment`.concat( window.location.search ) )}>{`Pay Invoice`}</button>
        <button className="btn btn-secondary w-80 print:hidden" onClick={handleGoBack}>{`Go Back`}</button>
      </div>
      <div className="mt-4">
        <p className="font-bold">{`Motif ACH Information for payment:`}</p>
        <p className="text-slategray">{`Bank account owner name: Motif Medical, LLC`}</p>
        <p className="text-slategray">{`Bank name: Truist Bank`}</p>
        <p className="text-slategray">{`Address: 232 Williamson Rd Mooresville, NC 28117`}</p>
        <p className="text-slategray">{`Account number: 1000156310400`}</p>
        <p className="text-slategray">{`Routing number: 061000104`}</p>
        <p className="text-slategray">{`SWIFT code: BRBTUS33`}</p>
      </div>
    </div>
  )
}