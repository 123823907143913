import { ItemBreakdown } from 'components/itemBreakdown'
import ThankYouHeader from 'components/thankYou/Header'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useInvoiceDataStore, usePaymentStore } from 'store'
import { formatDate, moneyFormatter } from 'utils/functions'

function Confirmation() : JSX.Element {
  const { lastTransactionId } = usePaymentStore()

  const { invoiceData, selectMultipleInvoices, selectedInvoicesTotal, resetSelectedInvoices, selectedInvoices, getInvoiceByNumber } = useInvoiceDataStore( ( store ) => ({
    invoiceData: store.getCurrentInvoice(),
    selectMultipleInvoices: store.selectMultipleInvoices(),
    selectedInvoicesTotal: store.getSelectedInvoicesSum(),
    resetSelectedInvoices: store.resetSelectedInvoices,
    selectedInvoices: store.selectedInvoices,
    getInvoiceByNumber: store.getInvoiceByNumber
  }) )

  const invoiceTotal = selectMultipleInvoices ? selectedInvoicesTotal : invoiceData?.invoice_total

  const navigate = useNavigate()
  useEffect( () => {
    window.onpopstate = () => navigate( `/${window.location.search}` )

    return () => { window.onpopstate = () => { return } }
  }, [] )

  function handlePayAnotherInvoice() {
    resetSelectedInvoices()

    return navigate( `/${window.location.search}` )
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="mb-10 print:mb-0">
        <ThankYouHeader />
        <h1 className="font-sans font-bold text-4xl">{`${moneyFormatter.format( invoiceTotal || 0 )} has been paid`}</h1>
        <p className="text-left self-start mt-5 ml-1 text-teal print:mt-0">{`${selectMultipleInvoices ? `Invoices` : `Invoice`} paid ${formatDate( Date() )}`}</p>
        <p className="text-left self-start mt-5 ml-1 text-teal print:mt-0">{`Transaction Id: ${lastTransactionId}`}</p>
      </div>
      {
        !selectMultipleInvoices && invoiceData ? <ItemBreakdown invoice={invoiceData} />
          :
          <>
            <h1 className="font-sans font-bold text-4xl mb-4">{`Invoices paid`}</h1>
            {
              selectedInvoices.map( ( invoiceNumber ) => {
                const _invoice = getInvoiceByNumber( invoiceNumber )

                if ( _invoice ) {
                  return (
                    <div className="w-full" key={invoiceNumber}>
                      <ItemBreakdown invoice={_invoice} />
                    </div>
                  )
                }
              })
            }
          </>
      }
      <button onClick={() => window.print()} className="btn btn-primary mx-auto my-4 w-96 print:hidden">{`Download PDF`}</button>
      <button className="btn btn-secondary mx-auto my-4 w-96 print:hidden" onClick={handlePayAnotherInvoice}>{`Pay Another Invoice`}</button>
    </div>
  )
}

export default Confirmation