import { useEffect } from "react"
import { usePaymentStore } from "store"
import { BankPaymentMethod, IPaymentMethod, identifyPaymentMethodType } from "types/paymentMethods.d"
import BankInformation from "./BankInformation"

function PayByCard() : JSX.Element {

  const { paymentMethods, replaceAtKey, isEmpty, addNewPaymentMethod } = usePaymentStore()

  useEffect( () => {
    if ( isEmpty( `ach` ) ) addNewPaymentMethod( `ach` )
  }, [ paymentMethods ] )


  return (
    <>
      <p className="my-4">{`Pay by bank account`}</p>
      {paymentMethods?.map( ( paymentMethod, key ) => {
        if ( identifyPaymentMethodType( paymentMethod ) === `card` ) return null

        return (
          <BankInformation
            // eslint-disable-next-line react/no-array-index-key
            key={( paymentMethod as BankPaymentMethod )?.paymentProfileId ?? key}
            paymentMethod={paymentMethod as BankPaymentMethod}
            setPaymentMethod={( pm : BankPaymentMethod | null ) => replaceAtKey( pm as IPaymentMethod, key )}
            addNewPaymentMethod={() => addNewPaymentMethod( `ach` )}
            editView={!( ( paymentMethod as BankPaymentMethod )?.paymentProfileId )}
          /> )
      })}
    </>
  )
}

export default PayByCard