export function getHashFromUrl(){
  const urlParams = new URLSearchParams( window.location.search )
  const patientHash = urlParams.get( `sgh` ) ?? urlParams.get( `txt` )

  return patientHash
}

export const moneyFormatter = new Intl.NumberFormat( `en-US`, {
  style: `currency`,
  currency: `USD`
})

export const removeInitialZeroChar = ( str : string ) => str.replace( /^0+/, `` )

export const formatDate = ( date : string ) => {

  // note: This function was authored on the af sleep codebase by @AsherWebb
  // @NOTE this function accepts 2 data types which requires a bifibrucation to handle spot on
  // if eligible is immediately a new js Date constructed object is passed to the function
  // if eligible is the future we get a string `2022-01-09` would be the example formatting
  // we match for regex and just work at the string level for that ^

  const expectedUnserializedFormat = /\d{4}[-]\d{2}[-]\d{2}/

  if ( typeof date === `string` && date.match( expectedUnserializedFormat ) ) {

    const subStrings = date.substring( 0, 10 ).split( `-` )

    const [ year, month, dateDD ] = subStrings
    const _month = removeInitialZeroChar( month )
    const _date = removeInitialZeroChar( dateDD )

    return `${_month}/${_date}/${year}`

  }

  const d = new Date( date )

  let f_date = ``
  if ( d ) f_date = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`

  return f_date
}