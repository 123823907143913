export type IPaymentMethod = CardPaymentMethod | BankPaymentMethod

export interface CardPaymentMethod {
  cardholderName: string;
  cardNumber: string;
  expMonth: string;
  expYear: string;
  ccv: string;
  zip: string;
  state?: string;
  city?: string;
  address?: string;
  issuer?: string;
  paymentProfileId?: string;
}

export interface BankPaymentMethod {
  routingNumber: string;
  accountNumber: string;
  nameOnAccount: string;
  accountType?: string; // This should be 'checking' most of the time
  paymentProfileId?: string;
}

export function identifyPaymentMethodType( payMethod : IPaymentMethod | null ) {
  const cardPayment = payMethod as CardPaymentMethod
  const bankPayment = payMethod as BankPaymentMethod
  if ( cardPayment?.cardNumber ||  cardPayment?.cardNumber === ``) return `card`
  if ( bankPayment?.accountNumber ||  bankPayment?.accountNumber === ``) return `ach`

  return null
}

export interface AuthnetPaymentProfile {
  customerPaymentProfileId: string,
  bankAccount?: BankPaymentMethod
}

export function serializeAuthnetPaymentProfileResponse( authnetPaymentProfiles : AuthnetPaymentProfile[]) : IPaymentMethod[] {
  return authnetPaymentProfiles?.map( ( profile : AuthnetPaymentProfile ) => {
    const { bankAccount, billTo, creditCard } = profile?.payment
    if ( bankAccount?.accountNumber.length ) {
      return {
        paymentProfileId: profile.customerPaymentProfileId,
        nameOnAccount: bankAccount.nameOnAccount,
        routingNumber: bankAccount.routingNumber,
        accountNumber: bankAccount.accountNumber,
        accountType: bankAccount.accountType
      }
    }

    return {
      paymentProfileId: profile.customerPaymentProfileId,
      cardholderName: `${billTo?.firstName ?? ``} ${billTo?.lastName ?? ``}`,
      expMonth: ``,
      expYear: ``,
      cardNumber: creditCard?.cardNumber ?? ``,
      address: billTo?.address ?? ``,
      city: billTo?.city ?? ``,
      state: billTo?.state ?? ``,
      zip: billTo?.zip ?? ``,
      ccv: ``,
      issuer: creditCard?.issuer ?? ``
    }
  })
}