import { useState, useRef, RefObject, useMemo } from "react"
import { TextInput } from "components/forms/library"
import { BankPaymentMethod } from "types"
import { getValidationHelper } from "components/forms/validation"
import { SubmitPaymentButton } from "components/customButtons"
import { SavePaymentMethodCheckboxInput } from "components/savePaymentMethod"

export type EditBankInformationProps = {
  payMethod: BankPaymentMethod;
  setPayMethod: ( _pm : BankPaymentMethod ) => void;
}

export default function EditBankInformation({
  payMethod, setPayMethod
} : EditBankInformationProps ) {

  const [ currentFocus, setCurrentFocus ] = useState( `` )
  const [ errors, setErrors ] = useState<Record<string, string>>({
    accountNumber: ``,
    routingNumber: ``,
    nameOnAccount: ``
  })
  const [ shouldSavePaymentMethod, setShouldSavePaymentMethod ] = useState( false )
  const [ submitPaymentError, setSubmitPaymentError ] = useState( `` )

  const inputRefs = {
    accountNumber: useRef<HTMLInputElement>( null ),
    routingNumber: useRef<HTMLInputElement>( null ),
    nameOnAccount: useRef<HTMLInputElement>( null )
  } as Record<string, RefObject<HTMLInputElement>>

  function handleInputChange( event : React.ChangeEvent<HTMLInputElement> ) {
    const { name, value } = event.currentTarget
    // Pass up data
    setPayMethod({
      ...payMethod,
      [name]: value
    })
    // Check for errors
    const { errorMessage, validator } = getValidationHelper( name )
    const _errors = {
      ...errors
    }
    // If valid set error to empty string
    _errors[ name ] = validator( value ) ? `` : errorMessage
    setErrors( _errors )
  }

  function handleBlur() {
    setCurrentFocus( `` )
  }

  function handleFocus( event : React.ChangeEvent<HTMLInputElement> ) {
    setCurrentFocus( event.target.name )
  }

  function handleSavePaymentMethod() {
    setShouldSavePaymentMethod( !shouldSavePaymentMethod )
  }

  const formComplete = useMemo( () => Object.values( inputRefs )?.every( ( input ) => Boolean( input?.current?.value?.length ) ), [ errors ] )

  const inputHandlers = {
    onChange: handleInputChange,
    onBlur: handleBlur,
    onFocus: handleFocus
  }

  return (
    <div>
      <TextInput
        id="routingNumber"
        name="routingNumber"
        placeholder="Routing number"
        value={payMethod.routingNumber}
        errorMessage={currentFocus !== `routingNumber` && errors.routingNumber}
        required
        reference={inputRefs.routingNumber}
        {...inputHandlers}
      />

      <TextInput
        id="accountNumber"
        name="accountNumber"
        placeholder="Account number"
        value={payMethod.accountNumber}
        errorMessage={currentFocus !== `accountNumber` && errors.accountNumber}
        required
        reference={inputRefs.accountNumber}
        {...inputHandlers}
      />

      <TextInput
        id="nameOnAccount"
        name="nameOnAccount"
        placeholder="Name on account"
        value={payMethod.nameOnAccount}
        errorMessage={currentFocus !== `nameOnAccount` && errors.nameOnAccount}
        required
        reference={inputRefs.nameOnAccount}
        {...inputHandlers}
      />
      <SavePaymentMethodCheckboxInput
        payMethodType="ach"
        value={shouldSavePaymentMethod} onChange={handleSavePaymentMethod}
      />
      <p className="text-error my-2">{submitPaymentError}</p>
      <SubmitPaymentButton
        disabled={!formComplete || Boolean( Object.values( errors ).find( ( error ) => error !== `` ) )}
        payMethod={payMethod}
        shouldSavePaymentMethod={shouldSavePaymentMethod}
        onSubmitError={( errorMessage ) => setSubmitPaymentError( errorMessage )}
      />
    </div>
  )
}