import { useState } from 'react'
import { CardPaymentMethod } from 'types'
import EditCardInformation from './EditCardInformation'
import { SubmitPaymentButton, PaymentMethodButtonGroup } from 'components/customButtons'
import { deletePaymentMethod } from 'authnet/api'
import { reportToSentry } from "utils/reportToSentry"


export type CardInformationProps = {
  paymentMethod : CardPaymentMethod;
  setPaymentMethod: ( _pM : CardPaymentMethod | null ) => void;
  addNewPaymentMethod: () => void;
  editView?: boolean;
  readOnly?: boolean;
}

export default function CardInformation({paymentMethod, setPaymentMethod, addNewPaymentMethod, editView, readOnly = false} : CardInformationProps ) : JSX.Element {


  const [ errorMessage, setErrorMessage ] = useState( `` )

  function handleDelete() {
    if ( !paymentMethod?.paymentProfileId ) return // This should never be true but typescript
    deletePaymentMethod( paymentMethod.paymentProfileId ).catch( ( error ) => {
      reportToSentry( new Error( `There was an error deleting the payment method`, {
        cause: error
      }) )
    })
    setPaymentMethod( null )
  }

  if ( editView && !readOnly ) {
    return <EditCardInformation payMethod={paymentMethod} setPayMethod={( pM : CardPaymentMethod ) => setPaymentMethod( pM )} />
  }

  return (
    <div className="border-2 rounded-lg border-lightslategray p-4 my-4">
      <p className="underline text-lg md:text-xl text-left font-light max-w-2xl mb-2">
        {`${ paymentMethod?.issuer ?? `Credit`} Card ending in ${ paymentMethod.cardNumber?.slice( -4 )}`}
      </p>

      {!readOnly && (
        <>
          <PaymentMethodButtonGroup onDelete={handleDelete} onNew={() => { addNewPaymentMethod() }} />
          { errorMessage && <p className="text-error">{errorMessage}</p>}
          <SubmitPaymentButton payMethod={paymentMethod} shouldSavePaymentMethod onSubmitError={setErrorMessage} />
        </> ) }
    </div>
  )
}