import { SubmitPaymentButton, PaymentMethodButtonGroup } from 'components/customButtons'
import { capitalize } from 'lodash'
import { useState } from 'react'
import { BankPaymentMethod } from "types/paymentMethods"
import EditBankInformation from './EditBankInformation'
import { deletePaymentMethod } from 'authnet/api'
import { reportToSentry } from "utils/reportToSentry"

export type ViewBankInformationProps = {
  paymentMethod: BankPaymentMethod;
  setPaymentMethod: ( _ach : BankPaymentMethod | null ) => void;
  addNewPaymentMethod: () => void;
  editView?: boolean;
  readOnly?: boolean;
}

export default function BankInformation({paymentMethod, setPaymentMethod, addNewPaymentMethod, editView, readOnly=false} : ViewBankInformationProps ) {

  const [ errorMessage, setErrorMessage ] = useState( `` )

  function handleDelete() {
    if ( !paymentMethod?.paymentProfileId ) return // This should never be true but typescript
    deletePaymentMethod( paymentMethod.paymentProfileId ).catch( ( error ) => {
      reportToSentry( new Error( `There was an error deleting the payment method`, {
        cause: error
      }) )
    })
    setPaymentMethod( null )
  }

  if ( editView && !readOnly ) return <EditBankInformation payMethod={paymentMethod} setPayMethod={setPaymentMethod} />

  // TODO: update the ux a little bit
  return (
    <div className="border-2 rounded-lg border-lightslategray p-4 my-4">
      <p className="underline text-lg md:text-xl text-left font-light max-w-2xl mb-2">
        {`${ paymentMethod?.accountType ? capitalize( paymentMethod.accountType ) : `Bank`} account ending in ${ paymentMethod.accountNumber?.slice( -4 )}`}
      </p>

      {!readOnly && (
        <>
          <PaymentMethodButtonGroup onNew={addNewPaymentMethod} onDelete={handleDelete} />
          <p className="text-error">{errorMessage}</p>
          <SubmitPaymentButton payMethod={paymentMethod} shouldSavePaymentMethod onSubmitError={setErrorMessage} />
        </>
      )}

    </div>
  )
}