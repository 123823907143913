
import { moneyFormatter } from "utils"

export type DetailLineItemProps = {
  title: string;
  titleClassName?: string;
  amount?: number;
}

export default function DetailLineItem({title, amount, titleClassName } : DetailLineItemProps ) {
  return (
    <div className="flex justify-between">
      <div>
        <p className={titleClassName}>{title}</p>
      </div>
      <div>
        {amount ? (
          <p>{moneyFormatter.format( amount )}</p> ) : (
          <p>{`$0`}</p>
        )}
      </div>
    </div>
  )
}